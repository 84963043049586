import auth from './auth'
import users from './users'
import jobs from './jobs'
import stats from '@/http/stats'

export default function useHttpService() {
  return {
    auth,
    users,
    jobs,
    stats
  }
}
