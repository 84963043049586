import { computed, ref } from '@vue/composition-api'

export default function useTableHelper() {

  const refTable = ref(null)

  const perPage = ref(10)
  const totalCount = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(null)
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refTable.value ? refTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCount.value,
    }
  })

  const refetchData = () => {
    refTable.value.refresh()
  }

  return {
    refTable,

    perPage,
    totalCount,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,

    dataMeta,
    refetchData
  }
}
